<template>
  <div>
    <div class="sctp-container sctp-pad-tb20 container clear">
      <div style="margin-top: -10px;">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>源码大厅</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="sctp-bg-white sctp-pad-tb10 sctp-mar-tb10">
        <condition
          @select="onConditionSelect"
          :data="conditionData"
          :value="conditionDefaultValue"
        ></condition>
      </div>
      <div class="goods-info">
        <el-row :gutter="10">
          <el-col :span="18">

            <el-row :gutter="10">
              <el-col :span="24">
                <div
                  class="sctp-flex sctp-flex-sb sctp-flex-aic sctp-pad10 sctp-bg-white ">
                  <div class="flex">
                    <el-button-group>
                      <el-button
                        size="mini"
                        @click="sourceCodeOrderBy('')"
                      >
                        综合
                      </el-button>
                      <el-button size="mini" @click="sourceCodeOrderBy('createtime')">公开时间 {{
                          orderByStatus('createtime') === 0 ? '' : orderByStatus('createtime') > 0
                            ? '▲' : '▼'
                        }}
                      </el-button>
                      <el-button size="mini" @click="sourceCodeOrderBy('saleprice')">价格 {{
                          orderByStatus('saleprice') === 0 ? '' : orderByStatus('saleprice') > 0
                            ? '▲' : '▼'
                        }}
                      </el-button>
                    </el-button-group>
                    <div class="price-input-container">
                      <el-input size="mini" type="number"  v-model="sourceCodeParam.minPrice" @blur="blur1">
                        <div slot="prefix">¥</div>
                      </el-input>
                      ~
                      <el-input style="width:120px" size="mini"  type="number" v-model="sourceCodeParam.maxPrice" @blur="blur2">
                        <div slot="prefix">¥</div>
                      </el-input>
                      <el-button class="mg-l5" @click="getSctpsourceCodeList" size="mini">确认
                      </el-button>
                    </div>
                  </div>
                  <el-input size="mini" placeholder="商品标题" v-model="sourceCodeParam.goodsName"
                            :style="{width:'auto'}">
                    <el-button @click="getSctpsourceCodeList" slot="append"
                               icon="el-icon-search"></el-button>
                  </el-input>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="8"
                      class="goods-info-item"
                      v-for="sourceCode in sourceCodeList"
                      :key="sourceCode.productId"
              >
                <a :href="'/sourcecode/view/' + sourceCode.productId" target="_blank">
                  <div class="sctp-bg-white sctp-flex sctp-flex-column sctp-mar-t10">
                    <el-image
                      style="width: 100%;height: 200px;"
                      :src="sourceCode.productLogo" fit="cover"
                    ></el-image>
                    <div class="" style="padding: 0 8px 8px;">
                      <div class="sctp-ellipsis sctp-mar-tb5">{{ sourceCode.productName }}</div>
                      <div class="flex flex-sb fz-12 fc-info">
                        <div>
                          <span>{{sourceCode.subTypeName}}</span>
                        </div>
                        <div>
                          <span>{{ getCity(sourceCode.cityCode) }}</span>
                        </div>
                      </div>
                      <div class="divider"></div>
                      <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                        <div class="sctp-red">{{
                            sourceCode.productPrice ? `¥${sourceCode.productPrice}` : '免费'
                          }}
                        </div>
                        <div class="">浏览数：{{ sourceCode.hits || 0 }}</div>
                      </div>
                    </div>
                  </div>
                </a>
              </el-col>
            </el-row>

            <div class="sctp-pad-tb10 sctp-tr sctp-bg-white">
              <el-pagination
                :current-page="pageConfig.page"
                :page-size="pageConfig.pageSize"
                @size-change="pageConfig.handleSizeChange"
                @current-change="pageConfig.handleCurrentChange"
                :page-sizes="pageConfig.pageSizes"
                layout="prev, pager, next, total, sizes"
                :total="pageConfig.total">
              </el-pagination>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="sctp-bg-white">
              <div class="sctp-flex sctp-flex-sb sctp-pad-tb15">
                <div class="slider-card-title">推荐源码</div>
                <div>
                </div>
              </div>
              <template v-for="(recommendItem,index) in recommendProductConfig.data">
                <a target="_blank" :href="'/sourcecode/view/' + recommendItem.productId"
                   :key="recommendItem.productId">
                  <div class="sctp-flex sctp-wfull sctp-pad5">
                    <el-image
                      class="sctp-mar-r10"
                      :src="recommendItem.productLogo"
                      fit="cover"
                      style="width:100px;height:100px;"
                    ></el-image>
                    <div class="sctp-flex-item-1">
                      <div class="flex flex-sb flex-col full">
                        <div>
                          <div class="sctp-ellipsis--l3">{{ recommendItem.productName }}</div>
                          <div class="fz-12 fc-info">{{ recommendItem.codeTypeName }}</div>
                        </div>
                        <div class="sctp-mar-t5"><span>价格：</span><span class="sctp-red">¥{{
                            recommendItem.productPrice
                          }}</span></div>
                      </div>
                    </div>
                  </div>
                  <template v-if="index !== recommendProductConfig.data.length - 1">
                    <div class="divider"></div>
                  </template>
                </a>
              </template>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {sourceCode} from "@/apis/index";
import area from "@/resources/js/area";
import {common} from "@/apis"


export default {
  components: {
    condition: () => import('@PAGE/home/QueryMenu.vue')
  },
  data() {
    return {
      pageConfig: {
        page: 1,
        pageSize: 12,
        total: 0,
        pageSizes: [1, 2, 5, 10].map(item => item * 12),
        handleSizeChange: (pageSize) => {
          this.pageConfig.pageSize = pageSize;
          this.getSctpsourceCodeList()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.getSctpsourceCodeList()
        }
      },
      sourceCodeParam: {
        typeid: null,
        subtypeid: null,
        reqtypeid: null,
        lngid: null,
        dbtypeid: null,
        orderBy: null,
        goodsName: null,
        useflag: null,
        copyright: null,
        minPrice: null,
        maxPrice: null,
      },
      goodsCondition: {
        type: [],
        subType: [],
        language: [],
        database: [],
        codeType: [],
      },
      sourceCodeList: [],
      recommendProductConfig: {
        data: [],
        page: 1,
        limit: 5
      }
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path === from.path) {
        this.loadRouterData();
        this.getSctpsourceCodeList();
      }
    }
  },
  methods: {
    blur1(){
      if(this.sourceCodeParam.minPrice!=null) {

        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.sourceCodeParam.minPrice))
        {
          this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
          this.sourceCodeParam.minPrice=0
          return
        }
        let a = this.sourceCodeParam.maxPrice * 1;
        let b = this.sourceCodeParam.minPrice * 1;

        if(b<0)
        {
          this.$alert('价格不能为负数')
          this.sourceCodeParam.minPrice=0
          return
        }
        if(a<b&&this.sourceCodeParam.maxPrice!=null)
        {
          this.$alert("最低价格不能超过最高价格")
          this.sourceCodeParam.minPrice=0
        }
      }
    },
    blur2(){
      if(this.sourceCodeParam.maxPrice!=null) {
          if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.sourceCodeParam.maxPrice))
          {
            this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
            this.sourceCodeParam.maxPrice=0
            return
          }

        let a = this.sourceCodeParam.maxPrice * 1;
        if(a<0)
        {
          this.$alert('价格不能为负数')
          this.sourceCodeParam.maxPrice=null
          return
        }
        let b = this.sourceCodeParam.minPrice * 1;
        if(a<b&&this.sourceCodeParam.minPrice!=null)
        {
          this.$alert("最高价格不能低于最低价格")
          this.sourceCodeParam.maxPrice=0
        }
      }
    },
    getGoodsConditions() {
      return sourceCode.condition()
      .then(res => {
        let allTypeChildren = [];
        let {type, language, database, codeType} = res.data;
        type.forEach(type => allTypeChildren = [...allTypeChildren, ...type.children]);
        this.goodsCondition.type = [{
          children: allTypeChildren,
          typeId: null,
          typeName: '全部'
        }, ...type];
        this.goodsCondition.subType = [{
          typeId: null,
          typeName: '全部'
        }, ...this.goodsCondition.type[0].children];
        this.goodsCondition.language = [{
          lngid: null,
          lngname: '全部'
        }, ...language];
        this.goodsCondition.database = [{
          dbtypeid: null,
          dbname: '全部'
        }, ...database];
        this.goodsCondition.codeType = [{
          reqtypeid: null,
          typename: '全部'
        }, ...codeType];
      })
    },
    selectGoodsCondition(sourceCodeParamKey, id, children) {
      this.sourceCodeParam[sourceCodeParamKey] = id;
      if (children) {
        this.changeGoodsTypeCondition(children)
      }
      this.pageConfig.page = 1;
      this.getSctpsourceCodeList();
    },
    changeGoodsTypeCondition(subTypeConditions) {
      this.sourceCodeParam.subtypeid = null;
      this.goodsCondition.subType = [{
        typeId: null,
        typeName: '全部'
      }, ...subTypeConditions]
    },
    getSctpsourceCodeList() {
      const {pageConfig: {page, pageSize}, sourceCodeParam} = this;
      sourceCode.centerData({
        typeId: sourceCodeParam.typeid,
        subTypeId: sourceCodeParam.subtypeid,
        codeTypeId: sourceCodeParam.reqtypeid,
        languageId: sourceCodeParam.lngid,
        dbTypeId: sourceCodeParam.dbtypeid,
        orderBy: sourceCodeParam.orderBy,
        goodsName: sourceCodeParam.goodsName,
        useFlag: sourceCodeParam.useflag,
        copyright: sourceCodeParam.copyright,
        minPrice: sourceCodeParam.minPrice,
        maxPrice: sourceCodeParam.maxPrice,
        page: page,
        limit: pageSize
      }).then(res => {
        const {data = [], count = 0} = res;
        this.sourceCodeList = data;
        this.pageConfig.total = count
      })
    },
    sourceCodeOrderBy(field) {
      let result = null;
      if (field) {
        let ret = this.orderByStatus(field);
        if (ret === 0) {
          result = {field: field, orderByType: 'ASC'};
        } else if (ret === 1) {
          result = {field: field, orderByType: 'DESC'};
        }
      }
      this.sourceCodeParam.orderBy = result;
      this.getSctpsourceCodeList();
    },
    getRecommendGoods() {
      const {limit, page} = this.recommendProductConfig;
      return sourceCode.centerRecommend({
        page, limit,
      }).then(res => {
        const {data} = res;
        this.recommendProductConfig.data = data;
      })
    },
    onConditionSelect({label, target}) {
      switch (label) {
        case '一级分类':
          this.selectGoodsCondition('typeid', target.typeId, target.children);
          break;
        case '二级分类':
          this.selectGoodsCondition('subtypeid', target.typeId);
          break;
        case '主要开发语言':
          this.selectGoodsCondition('lngid', target.lngid);
          break;
        case '数据库':
          this.selectGoodsCondition('dbtypeid', target.dbtypeid);
          break;
        case '源码分类':
          this.selectGoodsCondition('reqtypeid', target.reqtypeid);
          break;
        case '软件用途':
          this.selectGoodsCondition('useflag', target.value);
          break;
        case '软件版权':
          this.selectGoodsCondition('copyright', target.value);
          break;
        default:
          break
      }
    },
    loadRouterData() {
      const {typeid, subtypeid, search} = this.getQuery();
      this.sourceCodeParam.goodsName = search || null;
      this.sourceCodeParam.typeid = typeid || null;
      this.sourceCodeParam.subtypeid = subtypeid || null;
    }
  },
  computed: {
    orderByStatus() {
      return function (field) {
        let {orderBy} = this.sourceCodeParam;
        if (orderBy && orderBy.field && orderBy.field === field) {
          if (!orderBy.orderByType || orderBy.orderByType === 'ASC') {
            return 1;
          }
          return -1;
        }
        return 0;
      }
    },
    conditionDefaultValue() {
      return {
        一级分类: null,
        二级分类: null,
        主要开发语言: null,
        数据库: null,
        源码分类: null,
        软件用途: null,
        软件版权: null,
      }
    },
    conditionData() {
      return [
        {
          label: '源码分类',
          values: this.goodsCondition.codeType
          .filter(item => !item.type || item.type === 1)
          .map(item => {
            item.title = item.typename;
            item.value = item.reqtypeid;
            return item
          })
        },
        {
          label: '一级分类',
          values: this.goodsCondition.type
          .filter(item => !item.type || item.type === 1)
          .map(item => {
            item.title = item.typeName;
            item.value = item.typeId;
            return item
          })
        },
        {
          label: '二级分类',
          values: this.goodsCondition.subType.map(item => {
            item.title = item.typeName;
            item.value = item.typeId;
            return item
          })
        },
        {
          label: '主要开发语言',
          values: this.goodsCondition.language.map(item => {
            item.title = item.lngname;
            item.value = item.lngid;
            return item
          })
        },
        {
          label: '数据库',
          values: this.goodsCondition.database.map(item => {
            item.title = item.dbname;
            item.value = item.dbtypeid;
            return item
          })
        },
        {
          label: '软件用途',
          values: [
            {value: null, title: '全部'},
            {value: 1, title: '仅供学习'},
            {value: 2, title: '商业用途'},
            {value: 3, title: '完善代码后可商用'},
          ],
        },
        {
          label: '软件版权',
          values: [
            {value: null, title: '全部'},
            {value: 1, title: '有版权证'},
            {value: 0, title: '无版权证'},
          ],
        },
      ]
    },
    getCity() {
      return function (code) {
        return area.parseCity(code).name;
      }
    },
  },
  mounted() {
    common.viewHistory({
      userId: (this.user && this.user.userId) || null,
      id: 8,
      title: '源码出售大厅',
      type: 8
    });
  },
  beforeMount() {
    this.loadRouterData();
    this.getSctpsourceCodeList();
    this.getGoodsConditions();
    this.getRecommendGoods()
  }
}
</script>

<style scoped lang="scss">
.goods-card {
  padding: 8px;
  cursor: pointer;
}

.price-input-container {
  /deep/ {
    .el-input {
      width: 60px;
    }

    .el-input__inner {
      padding: 0 0 0 20px;
    }

    .el-input__prefix {
      div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin-bottom: 8px;
  margin-top: 5px;
  width: 100%;
}
</style>
